import React, { useState, useEffect } from 'react';
import { atom, useAtom } from "jotai";
import { dashboard as loadDashboard } from "./helpers/AuthApiModule";
import { guestdashboard } from "./helpers/InfoApiModule";
import {
  DashboardConfiguration,
  getThemeByThemeType,
  GuiConfig,
  Theme
} from "./models/typescript/DashboardConfiguration";
import { CustomModal } from "./models/typescript/CustomModal";
import axios from "axios";
import { getCityFromPath } from "./app/auth";
import SuspenseContent from "./containers/SuspenseContent";

export const dashboardAtom = atom<DashboardConfiguration | null>(null);
export const filterAtom = atom("");
export const navbarAtom = atom<any[]>([]);
export const modalAtom = atom<CustomModal | null>(null);
export const webConfigAtom = atom<WebConfig | null>(null);

const params = new URLSearchParams(window.location.search);

interface WebConfig {
  serviceUrl: string;
  org_key: string;
  hash_router: boolean;
}

export const refreshDashboard = async (setDashboard: React.Dispatch<React.SetStateAction<DashboardConfiguration | null>>) => {

  let clientId = null;

// Durchlaufe alle Parameter und finde "clientid" unabhängig von der Groß-/Kleinschreibung
  params.forEach((value, key) => {
    if (key.toLowerCase() === 'clientid') {
      clientId = value;
      params.delete(key);
    }
  });

  if (clientId) {
    // Prüfe, ob noch weitere Parameter vorhanden sind
    const newUrl = params.toString()
      ? window.location.pathname + '?' + params.toString()
      : window.location.pathname;

    window.history.replaceState({}, document.title, newUrl);
    localStorage.setItem("client", clientId);
  }


  else {
    const token = localStorage.getItem("token");

    const tokenRange = localStorage.getItem("token_range");
    const cityFromPath = getCityFromPath() === null ? "null" : getCityFromPath();

    let didUserCityChange = false;

    if (tokenRange !== null) {
      // Wenn tokenRange nicht "null" als String ist und sich von cityFromPath unterscheidet
      if (tokenRange !== cityFromPath) {
        didUserCityChange = true;
      }
    }


    const getDashboard = didUserCityChange ? guestdashboard : token && token.toString().length > 0 ? loadDashboard : guestdashboard;
    if(didUserCityChange) {
      localStorage.removeItem("token");
      localStorage.removeItem("token_range")
      localStorage.removeItem("client")
      window.location.reload();
      return;
    }
    // @ts-ignore
    localStorage.setItem('token_range', getCityFromPath())

    const storedDashboard = await getDashboard(clientId);

    // Prüfen, ob der localStorage-Wert leer ist
    if (localStorage.getItem("client") === null || localStorage.getItem("client") === undefined) {
      // Wenn startupclientid existiert, diesen Wert übernehmen, sonst 0 setzen
      if (storedDashboard.startupclientid !== undefined && storedDashboard.startupclientid !== 0) {
        localStorage.setItem("client", storedDashboard.startupclientid.toString());
        //window.location.reload();
      } else {
        localStorage.setItem("client", "0");
      }
    }
  }

  // Andernfalls wird defaultmäßig 0 gesetzt, wenn zuvor nichts anderes ausgewählt wurde.
  if (localStorage.getItem("client") === null || localStorage.getItem("client") === undefined)
    localStorage.setItem("client", "0");

  /**
   * Laden der Dashboard Konfiguration
   */
  const token = localStorage.getItem("token");
  const client = localStorage.getItem("client");
  const getDashboard = token && token.toString().length > 0 ? loadDashboard : guestdashboard;
  const res = await getDashboard(client);

  await setDashboard({
    ...res,
    guiconfig: ({
      ...JSON.parse(atob(res.guiconfigcontent))
    }),
    loggedIn: !!token,
  });

};

interface DataLoadingProps {
  children: any;
}

const DataLoading = ({ children }: DataLoadingProps) => {
  const [dashboard, setDashboard] = useAtom(dashboardAtom);
  const [, setWebConfig] = useAtom(webConfigAtom);
  const [loading, setLoading] = useState(true);
  const [orgKey, setOrgKey] = useState<string | null>(null)

  useEffect(() => {

    // Überprüfen, ob der 'error'-Parameter in der URL vorhanden ist
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    if (params.has('error')) {
      params.delete('error'); // Entfernt den 'error'-Parameter
      url.search = params.toString();
      window.history.replaceState({}, '', url.toString()); // Aktualisiert die URL ohne Seitenneuladung
    }

    const fetchOrgKeyAndRefreshDashboard = async () => {
      try {
        const response = await fetch(`${process.env.PUBLIC_URL}/webconfig.json`);
        const data: WebConfig = await response.json();
        const orgKey = data.org_key;
        const serviceUrl = data.serviceUrl;
        setOrgKey(orgKey)
        axios.defaults.baseURL = serviceUrl;
        axios.defaults.headers.common['x-orgkey'] = orgKey;
        setWebConfig(data);

        await refreshDashboard(setDashboard);

      } finally {
        setLoading(false)
      }
    };

    fetchOrgKeyAndRefreshDashboard();

  }, [setDashboard, setWebConfig]);

  useEffect(() => {
    if(orgKey === null) return;
    axios.defaults.headers.common['x-orgkey'] = orgKey;

  }, [orgKey]);

  useEffect(() => {
    if (dashboard?.guiconfig && dashboard.guiconfig.theme) {
      console.log(dashboard.guiconfig)
      const guiConfig: GuiConfig = dashboard.guiconfig;

      if(guiConfig.stylesheetUrl) {
        const stylesheetLink = document.createElement("link");
        stylesheetLink.rel = "stylesheet";
        stylesheetLink.href = guiConfig.stylesheetUrl;

        document.head.appendChild(stylesheetLink);
        }

      // Setzen der Page Title -> Config Wert wird ausgewertet
      document.title = guiConfig.pageTitle || "Dashboard";

      // Setzen des dynamischen FavIcons
      const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement('link') as HTMLLinkElement;

      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = guiConfig.favIcon;

      document.getElementsByTagName('head')[0].appendChild(link);

      if(guiConfig.standardcolor !== null && guiConfig.standardcolor !== undefined) {

        const theme: Theme = getThemeByThemeType(guiConfig.standardcolor);

        document.documentElement.style.setProperty('--remote_theme_primary', theme.dark_primaryColor);
        document.documentElement.style.setProperty('--remote_theme_primary2', theme.dark_primaryColor_darker);
        return;
      } else {
        document.documentElement.style.setProperty('--remote_theme_primary', localStorage.getItem("theme") === "light" ? guiConfig.theme.primaryColor : guiConfig.theme.dark_primaryColor);
        document.documentElement.style.setProperty('--remote_theme_primary2', localStorage.getItem("theme") === "light" ? guiConfig.theme.primaryColor_lighter : guiConfig.theme.dark_primaryColor_darker);
      }
    }
    let localTheme = localStorage.getItem("theme");

    if(localTheme === undefined || localTheme === null) {
      localTheme = "light";
      localStorage.setItem("theme", "light");
    }

    document.documentElement.setAttribute('data-theme', localTheme);
  }, [dashboard]);

  if (loading) {
    return <SuspenseContent />; // Zeigt einen Ladeindikator an, solange die Konfiguration geladen wird
  }

  return children; // Rendert die Kinderkomponenten, sobald die Konfiguration geladen ist
};



export default DataLoading;
