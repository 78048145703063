import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getCityFromPath } from "./app/auth";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const ErrorView: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const query = useQuery();
  const details = query.get('errorText') || '';
  const toggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    // URL auf '/dashboard' ändern, ohne den Nutzer weiterzuleiten
    const cityName = getCityFromPath();
    window.history.replaceState(null, '', cityName ? '/' + cityName + "/app/dashboard?error" : '/app/dashboard?error');
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full text-center">
        <h1 className="text-3xl font-bold mb-4">Fehler</h1>
        <p className="mb-4">Die Webanwendung funktioniert nicht korrekt. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.</p>
        <button
          onClick={toggleDetails}
          className="text-blue-500 underline mb-2 focus:outline-none"
        >
          {isExpanded ? 'Informationen schließen' : 'Informationen zeigen'}
        </button>
        {isExpanded && (
          <pre className="bg-gray-700 p-4 rounded-lg text-left overflow-auto max-h-64">
            {details}
          </pre>
        )}
      </div>
    </div>
  );
};

export default ErrorView;
